import { Loading } from 'components/Loading'
import { NotionPage } from 'components'
import { resolveNotionPage } from 'lib/resolve-notion-page'
import { useRouter } from 'next/router'
import { PageProps } from 'lib/types'
import React from 'react'
import { evaluatePageRevalidation } from 'lib/utils'
import * as Sentry from '@sentry/nextjs'
/**
 *
 * @returns the websites that will be available and ISR should expect to render
 */
export async function getStaticPaths() {
  return {
    paths: [],
    fallback: true // fallback true allows sites to be generated using ISR
  }
}

export const getStaticProps = async ({ params: { site: domain } }) => {
  // Resolve and render the notion page
  try {
    const props = (await resolveNotionPage(domain)) as PageProps

    const revalidateNr = evaluatePageRevalidation(props)
    return { props, revalidate: revalidateNr }
  } catch (err) {
    if (err.message.includes('There is no matching site')) {
      return {
        redirect: {
          destination: 'https://create.simple.ink/404-page'
        }
      }
    }
    Sentry.captureException(err, {
      extra: {
        domain,
        from: 'getStaticProps - _sites/[site]/index.tsx'
      }
    })
    throw err
  }
}
/**
 * @props {site: site config from our backend, pagId: the notion page id, recordMap: an object of the elements in this page}
 * @returns a Notion page of type React component
 */
export default function NotionDomainPage(props) {
  const router = useRouter()
  // If the page hasn't been generated
  if (router.isFallback) {
    return <Loading />
  }
  props = { ...props, isSubPage: false }
  const content = <NotionPage {...props} />
  // console.log({content})
  return typeof content === 'object' ? content : null
}
